(function() {
	var $btn 		= $('.mobile__controls__btn');
	var $mainTopo 	= $('.main__topo');
	var $body 		= $('body');

	$btn.click(function(e) {
		e.preventDefault();

		var ariaExpanded = $btn.attr('aria-expanded');

		$mainTopo.addClass('main__topo--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('mobile__controls__btn--active');

		$body.append('<div class="menu__backdrop"></div>');

		$('.menu__backdrop')
			.fadeIn(600)
			.click(function() {
				$mainTopo.removeClass('main__topo--shown');

				$btn
					.attr('aria-expanded', 'false')
					.removeClass('mobile__controls__btn--active');

				$('.menu__backdrop').fadeOut(600, function() {
					$(this).remove();
				});
			});
	});

})();
(function() {
	var $btn 		= $('.mobile__controls__search');
	var $mblSearch 	= $('.pesquisa__mbl');
	var $body 		= $('body');

	$btn.click(function(e) {
		e.preventDefault();

		var ariaExpanded = $btn.attr('aria-expanded');

		$mblSearch.addClass('pesquisa__mbl--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('mobile__controls__search--active');

		$body.append('<div class="menu__backdrop"></div>');

		$('.menu__backdrop')
			.fadeIn(600)
			.click(function() {
				$mblSearch.removeClass('pesquisa__mbl--shown');

				$btn
					.attr('aria-expanded', 'false')
					.removeClass('mobile__controls__search--active');

				$('.menu__backdrop').fadeOut(600, function() {
					$(this).remove();
				});
			});
	});

})();