window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};



const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function verifyMidia () {
	var w = window.innerWidth;
	var size = 'xs';

	if(w < 500) {
		size = 'xs';
	}

	if(w >= 500) {
		size = 'sm';
	}

	if(w >= 768) {
		size = 'md';
	}

	if(w >= 992) {
		size = 'lg';
	}

	if(w >= 1200) {
		size = 'xl';
	}

	window.midiaSize = size;

	return size;
}

verifyMidia();

(function(){
	var ja = false;

	$(window).resize(function() {
		if(ja) return;

		setTimeout(function() {
			ja = false;
		}, (1000/60));

		verifyMidia();
	})
})();

function basePath () {
	let p = '';

	if($('body').data('path')) {
		p = $('body').data('path') + '/';
	}

	return p;
}

var page__header = $('.page__header,.page-header');

if(page__header.length > 0) {
	var title = page__header.find('.titulo__secao');

	title.css('--phantom-text', `'${title.text()}'`);
}



// window.addEventListener('load', function() {


// 	if(page__header.length > 0) {
// 		var title = page__header.find('.titulo__secao');
// 		var h1 = title.text().split(' ');
// 		var w = 1110;
// 		var h = 198;


// 		// page__header.append('<canvas id="#canvas"></canvas>');

// 		var canvas 		= document.createElement('canvas');

// 		if(canvas) {

// 			canvas.width = w;
// 			canvas.height = h;

// 			var s = (h / 2) - 2;

// 			var contexto 	= canvas.getContext('2d');

// 			contexto.font = `${s}px montserratblack`;
// 			contexto.strokeStyle = '#CCC';
// 			contexto.textAlign = 'center';

// 			contexto.strokeText(h1[0], (w/2), s);
// 			contexto.strokeText(h1[1], (w/2), s*2);

// 			title.append(canvas);
// 		}
// 	}
// });