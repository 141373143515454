var Lazyimage = (function() {
	function Lazyimage() {
		if(window.customElements) {
			this.customElement();
		}else {
			this.fallback();
		}
	}

	Lazyimage.prototype.fallback = function() {
		this.is = 'fallback';
		var self 	= this;
		var boundVerifier = self.fallbackVerifyer;

		function d() {
			var lazyimage = $('lazy-image');

			lazyimage.each(function() {
				if(this.getBoundingClientRect().top <= window.innerHeight) {
					if(!this.querySelector('img')) {
						var self = $(this);
						const img = document.createElement('img');

						img.src = $(this).attr('src') || '';
						img.alt = $(this).attr('alt') || '';

						this.appendChild(img);

						$(this).addClass('loaded');
					}
				}
			});

			requestAnimationFrame(d);
		}

		d();
	}

	Lazyimage.prototype.customElement = function () {
		this.is = 'customElements';

		class LazyImage extends HTMLElement {
			constructor() {
				super();
				this.src = this.getAttribute('src');
				this.alt = this.getAttribute('alt');

				const self = this;
				const observer = new IntersectionObserver((entries) => {
					$(entries).each(function(index, entry) {
						if(entry.isIntersecting) {
							const img = document.createElement('img');

				        	img.src = self.src || '';
				        	img.alt = self.alt || '';

				        	entry.target.appendChild(img);

				        	img.addEventListener('load', function() {
				        		$(entry.target).addClass('loaded');
				        	});

				        	observer.unobserve(entry.target);
						}
					});
				});

				observer.observe(this);
			}
		}

		customElements.define('lazy-image', LazyImage);
	}

	return new Lazyimage();
})();
